@import "src/common-ui/variables";

.pageDisplay {
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $colorWhite;
  height: 100%;

  &.visible {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
  }
}

.displayHeader {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: $spacer-small;
  border-bottom: $border solid $colorSteel;

  @include textHeader;

  .backButton {
    justify-self: flex-start;

    @include svgColor($colorSecondary);

    @include svgSize($spacer, $spacer);
  }
}
