@import "src/common-ui/variables";

$textGray: #4D4D4D;

.infoWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 12px;

  .infoIcon {
    margin-right: $spacer-medium;
  }

  .infoText {
    display: flex;
    flex-direction: column;
    line-height: 1.3em;
    padding-top: $spacer*0.2;
    text-align: left;
    overflow: hidden;

    @include textBody($colorBlack);

    &.noPadding {
      padding-top: 0;
    }

    &.noIcon {
      @include textBody($colorBlack, $font-weight-regular, 18px);
    }
  }

  .infoDriver {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    @include textBody($colorSecondary);
  }

  .infoTextBold {
    @include textBold;

    &.noIcon {
      @include textBody($colorBlack, $font-weight-semibold, 18px);
    }
  }

  .infoTextSecondary {
    overflow: hidden;
    color: $colorText;
  }

  .infoTextTertiary {
    color: $colorMediumGray;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.hireExtraInfo {
  display: flex;
  grid-gap: 12px;

  .hoursScheduled {
    display: flex;
    grid-gap: 5px;

    .icon {
      display: inline-flex;

      @include svgSize(16px, 16px);

      @include svgColor($colorGray);
    }

    .text {
      display: flex;
      grid-gap: 2px;

      @include textRegularLabel($colorDarkGray);
    }

    .good {
      @include svgColor($colorActionable);

      @include textRegularLabel($colorActionable);
    }

    .bad {
      @include svgColor($colorAlert);

      @include textRegularLabel($colorAlert);
    }
  }
}

.hoverInfo {
  position: relative;
  width: 100%;

  .company,
  .phoneNumber {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 1;
    width: 100%;

    @include transition(0.25s);
  }

  .phoneNumber {
    position: absolute;
    left: 0;
    top: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $spacer-small;
    align-items: center;
    justify-items: center;

    @include svgSize($spacer, $spacer);

    @include svgColor($colorGray);
  }

  .hidden {
    opacity: 0;
  }
}

.infoPhoneNumber {
  line-height: 1.3em;
  overflow: hidden;
  color: $colorText;
}

.callButton {
  display: flex;
  border: 2px solid #d8d8d8;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin: 8px 0px;
  height: 32px;
}

.sectionHeader {
  padding-bottom: $spacer;
  @include textSubheader($colorText, 1.1rem);
}


.InfoCardTooltip {
  padding: 10px;
  background-color: rgb(46, 46, 46);
  max-width: 10rem;

  > .label {
    @include textSmallLabel($color: $colorWhite, $fontWeight: $font-weight-regular);
  }

  > .value {
    @include textBody($fontSize: $font-size-small, $color: $textGray, $fontWeight: $font-weight-regular);
  }
}
.tooltip {
  // This makes it easier to mouse over the tooltip and keep it open
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 0px !important;
}

.infoTooltipIcon {
  
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  &.iconDispatch {
    padding-left: 0.25rem;
  }
  &.iconSchedule {
    padding-left: 0.25rem;
  }
}

.infoIcon {
  position: relative;
  padding-top: 0.1rem;
  padding-left: 0.25rem;
}

.nameAndTooltipContainer{
  display: flex;
}



