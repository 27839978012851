@import "src/common-ui/variables";

.markerContainer {
  $markerColor: $colorActionable;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 1.5em;
  background-color: $markerColor;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 12px;
  cursor: pointer;

  &.notAvailable {
    $markerColor: $colorGray;
    background-color: $markerColor;
  }

  &.idle {
    $markerColor: $colorIdle;
    background-color: $markerColor;
  }

  &.badData {
    $markerColor: $colorAlert;
    background-color: $markerColor;
  }

  &.lostConnection {
    $markerColor: $colorBlack;
    background-color: $markerColor;
  }

  &.active {
    z-index: 2;
    width: 1.5em;
    border: 1px solid $colorWhite;
    box-shadow: 0 0 0 4px rgba($markerColor, 0.2);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 5px rgba($markerColor, 0.2);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba($markerColor, 0.2);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 5px rgba($markerColor, 0.2);
    }
  }
}

@mixin bearings($markerColor) {

  &.E {
    left: 3.5em;
    top: .3em;
    border-top: .5em solid transparent;
    border-left: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
  }

  &.SE {
    left: 3em;
    top: 1.5em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-bottom: .5em solid $markerColor;
    transform: rotate(135deg);
  }

  &.S {
    left: 1em;
    top: 2em;
    border-top: .5em solid $markerColor;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
  }

  &.SW {
    left: -1em;
    top: 1.5em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-bottom: .5em solid $markerColor;
    transform: rotate(225deg);
  }

  &.W {
    left: -1em;
    top: .25em;
    border-top: .5em solid transparent;
    border-right: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
  }

  &.NW {
    left: -.75em;
    top: -.75em;
    border-top: .5em solid transparent;
    border-right: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
    transform: rotate(45deg);
  }

  &.N {
    left: 1em;
    top: -1em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-bottom: .5em solid $markerColor;
  }

  &.NE {
    left: 3em;
    top: -.75em;
    border-top: .5em solid transparent;
    border-left: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
    transform: rotate(-45deg);
  }
}

.bearingIndicator {
  $markerColor: $colorActionable;
  position: absolute;
  width: 0;
  height: 0;

  @include bearings($markerColor);

  &.notAvailable {
    $markerColor: $colorGray;

    @include bearings($markerColor);
  }

  &.idle {
    $markerColor: $colorIdle;

    @include bearings($markerColor);
  }

  &.badData {
    $markerColor: $colorAlert;

    @include bearings($markerColor);
  }

  &.lostConnection {
    $markerColor: $colorBlack;

    @include bearings($markerColor);
  }
}

.markerTextContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 1;

  @include transition(.25s);

  @include textBody($color: $colorWhite, $fontWeight: $font-weight-semibold, $fontSize: 11px);

  &.hidden {
    opacity: 0;
  }
}
