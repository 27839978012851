@use "sass:math";

@import "src/common-ui/variables.scss";

.footerContainer {
  display: flex;
  flex-grow: 1;
  background-color: $colorWhite;
  font-size: $font-size-px-small;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 1px 5px 1px $colorLightGray;
  border-top: 2px solid $colorLightGray;
  padding: $padding-small-px;
  max-height: 32px;
  .footerItem{
    display: inline-flex;
    position: relative;
    color: $colorText;

    @include transition(0.5s);
    &:hover{
      color: $colorIceBlue;
    }
  }
  .footerItem:after
   {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    top: 100%;
    right: 0;
    background: $colorIceBlue;
    transition: width .5s ease;
  }

  .footerItem:hover:after{
    width: 100%;
    left: 0;
    background: $colorIceBlue;
  }
}

